import React, { useState } from "react"
import gql from "graphql-tag"
import { useQuery } from "@apollo/client"
import { Alert, ListSelect, Loading, Tabs } from ".."
import { Size, SIZE_FRAGMENT, GetSizeCategories, GetSizeCategoriesVariables } from "../../graph"
import { formatSize } from "../../models"

const GET_SIZE_CATEGORIES = gql`
  query GetSizeCategories($providerId: ID) {
    sizeCategories(providerId: $providerId) {
      id
      name
      description
      architecture
      subcategories {
        id
        name
        description
        architecture
        sizes {
          ...Size
        }
      }
    }
  }

  ${SIZE_FRAGMENT}
`

type ParentCategory = GetSizeCategories["sizeCategories"][number]
type Category = ParentCategory["subcategories"][number]

export function SizeCategorySelect({
  providerId,
  selected,
  onChange,
  currentSizeId,
  availableSizeSlugs = []
}: {
  providerId: string
  selected: string | null
  onChange: (size: Size) => void
  currentSizeId?: string
  availableSizeSlugs?: string[]
}) {
  const [parentCategory, setParentCategory] = useState<ParentCategory | null>(null)
  const [category, setCategory] = useState<Category | null>(null)
  const sizeIsAvailable = (size: Size) => availableSizeSlugs.length === 0 || availableSizeSlugs.includes(size.slug)

  const { data, loading, error } = useQuery<GetSizeCategories, GetSizeCategoriesVariables>(GET_SIZE_CATEGORIES, {
    variables: { providerId }
  })

  if (error) throw error

  if (!data) return <Loading />
  if (loading) return <Loading />

  if (data.sizeCategories.length === 0) {
    return <Alert type="none">No sizes available</Alert>
  }

  if (parentCategory == null) setParentCategory(data.sizeCategories[0] ?? null)
  if (category == null) setCategory(data.sizeCategories[0]?.subcategories[0] ?? null)

  return (
    <div className="sizes-select">
      <div className="category-select spacing-bottom-sm">
        {data.sizeCategories.map(parent => (
          <div key={parent.id} className={`category ${parent === parentCategory ? "active" : ""}`}>
            <button
              type="button"
              onClick={() => {
                setParentCategory(parent)
                setCategory(parent.subcategories[0])
              }}
            >
              {parent.name}
            </button>
            <p>{parent.description}</p>
          </div>
        ))}
      </div>

      {parentCategory && (
        <div className="spacing-bottom-sm">
          <Tabs
            items={parentCategory.subcategories}
            format={category => category?.name ?? ""}
            active={category}
            onChange={subcategory => setCategory(subcategory)}
          />
        </div>
      )}

      {category && (
        <ListSelect
          key={category.id}
          items={category.sizes.filter(sizeIsAvailable)}
          format={defaultFormat}
          selected={selected}
          disabled={currentSizeId}
          onChange={(id: string) => {
            const item = category.sizes.find(size => size.id === id)
            if (item) onChange(item)
          }}
          empty={<Alert type="none">No available sizes for {category.name}</Alert>}
        />
      )}
    </div>
  )
}

const defaultFormat = (size: Size) => (
  <>
    <div>{`${formatSize(size, "cost")}/mo (${formatSize(size, "costPerHour")}/ho)`}</div>
    <div className="chips">
      <span className="chip">
        <span className="chip-label">RAM</span>
        <span className="chip-value">{formatSize(size, "memory")}</span>
      </span>
      <span className="chip">
        <span className="chip-label">CPU</span>
        <span className="chip-value">{formatSize(size, "processor")}</span>
      </span>
      <span className="chip">
        <span className="chip-label">DISK</span>
        <span className="chip-value">{formatSize(size, "storage")}</span>
      </span>
      <span className="chip">
        <span className="chip-label">TRAFFIC</span>
        <span className="chip-value">{formatSize(size, "bandwidth")}</span>
      </span>
    </div>
  </>
)
